<template>
  <div class="changelog">
    <h1>SPM Probes Changelog</h1>
  </div>
  <div class="mt-12 d-flex justify-start mb-6">
    <v-timeline truncate-line="start" side="end">

      <v-timeline-item class="mb-6" hide-dot>
        <span>TODAY</span>
      </v-timeline-item>


      <v-timeline-item v-for=" item in changeLog" :key="item.id" class="mb-4" dot-color="primary" size="small">
        <template v-slot:opposite>
          <div>
            <FormattedTimestamp :timestamp="item.timestamp" />
          </div>
        </template>

        <div class="d-flex justify-space-between flex-grow-1">

          <div v-if="item.action == 'INSERT'">
            <v-chip class="ms-0" color="green" label size="small">
              {{ item.action }}
            </v-chip>
            "{{ item.data.modelNameShort }}" by {{ item.username }}
          </div>

          <div v-else-if="item.action == 'UPDATE'">
            <v-chip class="ms-0" color="yellow" label size="small">
              {{ item.action }}
            </v-chip>
            "{{ item.data.oldData.modelNameShort }}" by {{ item.username }}
            <p v-if="item.data.oldData">Changes: {{ getDiff(item.data.oldData, item.data.newData) }}</p>
          </div>

          <div v-else-if="item.action == 'DELETE'">
            <v-chip class="ms-0" color="red" label size="small">
              {{ item.action }}
            </v-chip>
            Probe "{{ item.data.modelNameShort }}" by {{ item.username }}
          </div>

          <div v-else-if="item.action == 'EXPORT'">
            <v-chip class="ms-0" color="blue" label size="small">
              {{ item.action }}
            </v-chip>
            Revision {{ item.data.revision }} by {{ item.username }}
          </div>

          <div v-else>
            <v-chip class="ms-0" color="yellow" label size="small">
              {{ item.action }}
            </v-chip>
            by {{ item.username }}
          </div>


        </div>
      </v-timeline-item>


    </v-timeline>
  </div>
</template>
<script>


import axios from 'axios';
import { authHeader } from '../components/auth.script.js'
import { useAuthStore } from "../stores/auth.store.js"
import { frontendSettings as settings } from "../../settings";
import FormattedTimestamp from "../components/FormattedTimestamp.vue";

export default {
  data: () => ({
    changeLog: [],
  }),
  components: {
    FormattedTimestamp
  },
  computed: {
    timeline() {
      return this.events.slice().reverse()
    },
  },
  created() {
    this.fetchChangeLog();

    // const authStore = useAuthStore();
    // this.isAdmin = authStore.user.role.toUpperCase() == "ADMIN";
    // this.isEditor = authStore.user.role.toUpperCase() == "EDITOR";
    // this.isViewer = authStore.user.role.toUpperCase() == "VIEWER";
    // this.isGuest = authStore.user.role.toUpperCase() == "GUEST";
  },
  methods: {
    async fetchChangeLog() {

      let request = {
        url: settings.restV1Url + "/spmprobechangelog",
        method: "get",
        headers: authHeader(),
      };
      try {
        const response = await axios(request);
        this.changeLog = response.data;
      }
      catch (error) {
        console.log("fetchChangeLog() error: " + error)
        console.log("fetchChangeLog() error.response: " + JSON.stringify(error.response))
      }
    },
    getDiff(o1, o2) {
      let diff = Object.keys(o2).reduce((diff, key) => {
        if (o1[key] === o2[key]) {
          return diff
        }

        return {
          ...diff,
          [key]: o2[key]
        }
      }, {})

      return JSON.stringify(diff);
    }
    ,
    comment() {
      const time = (new Date()).toTimeString()
      this.events.push({
        id: this.nonce++,
        text: this.input,
        time: time.replace(/:\d{2}\sGMT-\d{4}\s\((.*)\)/, (match, contents, offset) => {
          return ` ${contents.split(' ').map(v => v.charAt(0)).join('')}`
        }),
      })

      this.input = null
    },
  },
}
</script>