import axios from "axios";
import { frontendSettings as settings } from "../../settings";

export const signup = async item => {
  let data = {
    email: item.email,
    password: item.password,
    firstName: item.firstName,
    lastName: item.lastName,
    role: item.role
  };
  let request = {
    url: settings.restV1Url + "/auth/signup",
    method: "post",
    headers: settings.restV1Headers,
    data: JSON.stringify(data)
  };

  const response = await axios(request);
  return response;
};

export const login = async item => {
  let data = {
    email: item.email,
    password: item.password
  };
  let request = {
    url: settings.restV1Url + "/auth/signin",
    method: "post",
    headers: settings.restV1Headers,
    data: JSON.stringify(data)
  };

  try {
    const response = await axios(request);
    return response;
  }
  catch (error) {
    console.log(JSON.stringify(error.response))
    return error.response
  }
};

export function logout() {
  localStorage.removeItem('user');
}

export function authHeader() {
  let user = JSON.parse(localStorage.getItem('user'));

  if (user && user.accessToken && user.signature) {
    // for Node.js Express back-end
    return {
      ...settings.restV1Headers,
      'x-access-token': user.accessToken,
      'x-access-signature': user.signature
    };
  } else {
    return {};
  }
}

export function loadUserInfo() {
  const user = JSON.parse(localStorage.getItem('user'));
  return user;
}

export function storeUserInfo(userinfo) {
  localStorage.setItem("user", JSON.stringify(userinfo));
}


