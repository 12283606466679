<script setup>
import { RouterView } from "vue-router";
import { useAuthStore } from "./stores/auth.store.js"
const authStore = useAuthStore();
</script>

<template>
  <v-card style="max-width: 4000px;">
    <v-layout style="max-width: 4000px;">
      <v-app-bar v-if="authStore.user" :elevation="1" color="teal-darken-4" :image="require('./assets/alps.jpg')">
        <template v-slot:default>
          <v-container style="max-width: 600px">
            <v-row no-gutters>
              <v-col class="v-toolbar-title"> Alphorn </v-col>
              <v-col><v-btn class="nav-link" to="/probes">Probes</v-btn></v-col>
              <v-col><v-btn class="nav-link" to="/changelog">Changelog</v-btn></v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:append>
          <v-btn class="nav-link" to="/profile">Settings</v-btn>
          <v-btn icon="mdi-logout" v-if="authStore.user" @click="logoutUser"></v-btn>
          <v-btn icon="mdi-information-outline" to="/about"></v-btn> <!-- 
                                                                                                    <v-btn icon="mdi-magnify"></v-btn>
                                                                                                    <v-btn icon="mdi-dots-vertical"></v-btn> 
                                                                                                  -->
        </template>
      </v-app-bar>
      <v-main style="min-height: 100vh; max-width: 4000px;">
        <v-container style="min-height: 100vh; max-width: 4000px;">
          <v-row no-gutters>
            <v-col>
              <router-view />
            </v-col>
          </v-row>

        </v-container>
      </v-main>
    </v-layout>
  </v-card>
</template>


<script>

export default {
  data() {
    return {
      active: false,
    };
  },
  methods: {
    logoutUser() {
      const authStore = useAuthStore();
      authStore.logout();
      this.$router.push("/login");
    }
  }
};
</script>