<template>
  <v-sheet width="400" class="mx-auto" style="margin-top: 400px;">
    <v-form validate-on="submit" @submit.prevent="submit">
      <v-text-field v-model="email" :rules="emailRules" label="E-Mail" autocomplete="email"></v-text-field>
      <v-text-field v-model="password" :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="PasswordRules" :type="showPassword ? 'text' : 'password'" name="input-10-1" label="Password"
        @click:append-inner="showPassword = !showPassword" autocomplete="current-password"></v-text-field>

      <v-btn type="submit" block class="mt-2">Login</v-btn>
    </v-form>
    <v-alert v-if="formResult.errorMsg !== ''" color="error" icon="$error" title="Login failed">{{
      formResult.errorMsg }}</v-alert>

  </v-sheet>
</template>
<script>

import { useAuthStore } from '../stores/auth.store.js'

export default {
  data: vm => ({
    email: '',
    password: '',
    showPassword: false,
    emailRules: [
      email => vm.checkEmail(email),
    ],
    PasswordRules: [
      password => vm.checkPassword(password),
    ],
    timeout: null,
    formResult: {
      errorMsg: ''
    }
  }),
  methods: {
    async submit(event) {
      this.loading = true;
      const results = await event
      if (results.valid) {
        const authStore = useAuthStore();
        const response = await authStore.login(this.email, this.password);
        this.formResult.errorMsg = response.message;
      }
      this.loading = false;
    },
    async checkEmail(email) {
      return new Promise(resolve => {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (!email) return resolve('Please enter your E-Mail.')
          if (email.indexOf('@') === -1) return resolve('Please enter a valid E-Mail.')

          return resolve(true)
        }, 100)
      })
    },
    async checkPassword(password) {
      return new Promise(resolve => {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (!password) return resolve('Please enter your password.')

          return resolve(true)
        }, 100)
      })
    },
  },
}
</script>