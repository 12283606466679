<template>
  <v-list-item :title="probe.modelName" class="mb-2">

    <template v-slot:prepend>
      <v-avatar size='x-large' v-if="probe.cantileverPicture != null"
        :image="'data:' + probe.cantileverPicture.contentType + ';base64,' + probe.cantileverPicture.data"
        @click="collapseItem">
      </v-avatar>
      <v-avatar size='x-large' v-if="probe.cantileverPicture == null"
        :image="require('../assets/dummyCantileverPicture.png')" @click="collapseItem">
      </v-avatar>
    </template>

    <template v-slot:title>
      <v-container>
        <v-row no-gutters>
          <v-col cols="8">
            <span class="text-h6 mb-2 .float-left">{{ probe.modelNameShort }} from {{ probe.manufacturer }}</span>
            <span class="text-body-2 mb-2 ml-4 .float-left"> R{{ probe.revision }}, updated
              <FormattedTimestamp :timestamp="probe.updatedAt" />
            </span>
          </v-col>
          <v-col cols="4"></v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:subtitle>

      <v-card style="background-color: transparent;">
        <v-tabs v-model="tab" density="compact" no-transition="true">
          <v-tab value="overview">Overview</v-tab>
          <v-tab value="cantilever">Cantilever</v-tab>
          <v-tab value="tip">Tip/Spike</v-tab>
          <v-tab value="price">Price</v-tab>
        </v-tabs>

        <v-card-text class="pa-0 ma-0">
          <v-window v-model="tab">
            <v-window-item value="overview" class="pa-0 ma-0">
              <v-container fluid class="pa-0 ma-0">
                <v-row class="pa-0 ma-0">
                  <v-col cols="5" class="pa-0 ma-0">
                    <v-table class="pa-0 ma-0" style="background-color: transparent;">
                      <tbody class="pa-0 ma-0">
                        <tr>
                          <td>Name Long</td>
                          <td>{{ probe.modelNameLong }}</td>
                        </tr>
                        <tr>
                          <td>Type</td>
                          <td>{{ probe.type }}</td>
                        </tr>
                        <tr>
                          <td>Spec Sheet</td>
                          <td>{{ probe.specSheet }}</td>
                        </tr>
                        <tr>
                          <td>Applications</td>
                          <td>{{ probe.applications }}</td>
                        </tr>
                      </tbody>
                    </v-table>
                  </v-col>
                  <v-col cols="2" class="pa-0 ma-0"></v-col>
                  <v-col cols="5" class="pa-0 ma-0">
                    <v-table style="background-color: transparent;">
                      <tbody>
                        <tr>
                          <td>Description</td>
                          <td>{{ probe.description }}</td>
                        </tr>
                        <tr>
                          <td>favorite</td>
                          <td>{{ probe.favorite }}</td>
                        </tr>
                        <tr>
                          <td>status</td>
                          <td>{{ probe.status }}</td>
                        </tr>
                        <tr>
                          <td>Created</td>
                          <td>{{ probe.createdAt }}</td>
                        </tr>
                        <tr>
                          <td>Updated</td>
                          <td>{{ probe.updatedAt }}</td>
                        </tr>
                        <tr>
                          <td>Revision</td>
                          <td>{{ probe.revision }}</td>
                        </tr>
                        <tr>
                          <td>Comments</td>
                          <td>{{ probe.comments }}</td>
                        </tr>
                      </tbody>
                    </v-table>
                  </v-col>
                </v-row>
              </v-container>

            </v-window-item>

            <v-window-item value="cantilever">
              <v-container fluid class="pa-0 ma-0">
                <v-row class="pa-0 ma-0">
                  <v-col cols="5" class="pa-0 ma-0">
                    <v-table class="pa-0 ma-0" style="background-color: transparent;">
                      <tbody class="pa-0 ma-0">
                        <tr>
                          <td>Shape</td>
                          <td>{{ probe.shape }}</td>
                        </tr>
                        <tr>
                          <td>Length</td>
                          <td>{{ probe.lengthNom }} um ({{ probe.lengthMin }} - {{ probe.lengthMax }})</td>
                        </tr>
                        <tr>
                          <td>Width</td>
                          <td>{{ probe.widthNom }} um ({{ probe.widthMin }} - {{ probe.widthMax }})</td>
                        </tr>
                        <tr>
                          <td>Thickness</td>
                          <td>{{ probe.thicknessNom }} um ({{ probe.thicknessMin }} - {{ probe.thicknessMax }})</td>
                        </tr>
                        <tr>
                          <td>Spring Constant</td>
                          <td>{{ probe.springConstantNom }} N/m ({{ probe.springConstantMin }} - {{
                            probe.springConstantMax
                          }})</td>
                        </tr>
                        <tr>
                          <td>Resonance Frequency (Air)</td>
                          <td>{{ probe.resonanceFrequencyAirNom }} kHz ({{ probe.resonanceFrequencyAirMin }} - {{
                            probe.resonanceFrequencyAirMax }})</td>
                        </tr>
                        <tr>
                          <td>Resonance Frequency (Liquid)</td>
                          <td>{{ probe.resonanceFrequencyLiquidNom }} kHz ({{ probe.resonanceFrequencyLiquidMin }} - {{
                            probe.resonanceFrequencyLiquidMax }})</td>
                        </tr>
                        <tr>
                          <td>Resonance Frequency (Vacuum)</td>
                          <td>{{ probe.resonanceFrequencyVacuumNom }} kHz ({{ probe.resonanceFrequencyVacuumMin }} - {{
                            probe.resonanceFrequencyVacuumMax }})</td>
                        </tr>
                      </tbody>
                    </v-table>
                  </v-col>
                  <v-col cols="2" class="pa-0 ma-0"></v-col>
                  <v-col cols="5" class="pa-0 ma-0">
                    <v-table style="background-color: transparent;">
                      <tbody>
                        <tr>
                          <td>Material</td>
                          <td>{{ probe.material }}</td>
                        </tr>
                        <tr>
                          <td>Reflex Coating (material/Type)</td>
                          <td>{{ probe.reflexCoatingMaterial }} / {{ probe.reflexCoatingType }}</td>
                        </tr>
                        <tr>
                          <td>AutoAlign Strategy</td>
                          <td>{{ probe.autoAlignStrategy }}</td>
                        </tr>
                        <tr>
                          <td>AutoAlign Status</td>
                          <td>{{ probe.autoAlignStatus }}</td>
                        </tr>
                        <tr>
                          <td>Deflection Sensitivity Chi</td>
                          <td>{{ probe.deflectionSensitivityChi }}</td>
                        </tr>
                        <tr>
                          <td>Quality Factor (Air)</td>
                          <td>{{ probe.qualityFactorAirNom }} ({{ probe.qualityFactorAirMin }} -
                            {{ probe.qualityFactorAirMax }})</td>
                        </tr>
                        <tr>
                          <td>Quality Factor (Liquid)</td>
                          <td>{{ probe.qualityFactorLiquidNom }} ({{ probe.qualityFactorLiquidMin }} -
                            {{ probe.qualityFactorLiquidMax }})</td>
                        </tr>
                        <tr>
                          <td>Quality Factor (vacuum)</td>
                          <td>{{ probe.resonanceFrequencyVacuumNom }} ({{ probe.resonanceFrequencyVacuumMin }} -
                            {{ probe.resonanceFrequencyVacuumMax }})</td>
                        </tr>
                      </tbody>
                    </v-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-window-item>

            <v-window-item value="tip">
              <v-container fluid class="pa-0 ma-0">
                <v-row class="pa-0 ma-0">
                  <v-col cols="5" class="pa-0 ma-0">
                    <v-table class="pa-0 ma-0" style="background-color: transparent;">
                      <tbody class="pa-0 ma-0">
                        <tr>
                          <td>Tip Radius</td>
                          <td>{{ probe.tipRadiusNom }} nm ({{ probe.tipRadiusMin }} - {{ probe.tipRadiusMax }})</td>
                        </tr>
                        <tr>
                          <td>Tip Height</td>
                          <td>{{ probe.tipHeightNom }} um ({{ probe.tipHeightMin }} - {{ probe.tipHeightMax }})</td>
                        </tr>
                        <tr>
                          <td>Tip Front Angle</td>
                          <td>{{ probe.tipFrontAngleNom }}° ({{ probe.tipFrontAngleMin }} - {{ probe.tipFrontAngleMax }})
                          </td>
                        </tr>
                        <tr>
                          <td>Tip Back Angle</td>
                          <td>{{ probe.tipBackAngleNom }}° ({{ probe.tipBackAngleMin }} - {{ probe.tipBackAngleMax }})
                          </td>
                        </tr>
                        <tr>
                          <td>Tip Side Angle</td>
                          <td>{{ probe.tipSideAngleNom }}° ({{ probe.tipSideAngleMin }} - {{ probe.tipSideAngleMax }})
                          </td>
                        </tr>
                        <tr>
                          <td>Tip Shape</td>
                          <td>{{ probe.tipShape }}</td>
                        </tr>
                        <tr>
                          <td>Tip Material</td>
                          <td>{{ probe.tipMaterial }}</td>
                        </tr>
                        <tr>
                          <td>Tip Coating</td>
                          <td>{{ probe.tipCoating }}</td>
                        </tr>
                      </tbody>
                    </v-table>
                  </v-col>
                  <v-col cols="2" class="pa-0 ma-0"></v-col>
                  <v-col cols="5" class="pa-0 ma-0">
                    <v-table style="background-color: transparent;">
                      <tbody>
                        <tr>
                          <td>Spike Radius</td>
                          <td>{{ probe.spikeRadiusNom }} nm ({{ probe.spikeRadiusMin }} - {{ probe.spikeRadiusMax }})
                          </td>
                        </tr>
                        <tr>
                          <td>Spike Height</td>
                          <td>{{ probe.spikeHeightNom }} um ({{ probe.spikeHeightMin }} - {{ probe.spikeHeightMax }})
                          </td>
                        </tr>
                        <tr>
                          <td>Spike Tilt</td>
                          <td>{{ probe.spikeTiltNom }}° ({{ probe.spikeTiltMin }} - {{ probe.spikeTiltMax }}) </td>
                        </tr>
                        <tr>
                          <td>Spike HalfAngle</td>
                          <td>{{ probe.spikeHalfAngleNom }}° ({{ probe.spikeHalfAngleMin }} - {{ probe.spikeHalfAngleMax
                          }}) </td>
                        </tr>
                        <tr>
                          <td>Spike Shape</td>
                          <td>{{ probe.spikeShape }}</td>
                        </tr>
                        <tr>
                          <td>Spike Material</td>
                          <td>{{ probe.spikeMaterial }}</td>
                        </tr>
                      </tbody>
                    </v-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-window-item>

            <v-window-item value="price">
              <v-container fluid class="pa-0 ma-0">
                <v-row class="pa-0 ma-0">
                  <v-col cols="5" class="pa-0 ma-0">
                    <v-table class="pa-0 ma-0" style="background-color: transparent;">
                      <tbody class="pa-0 ma-0">
                        <tr>
                          <td>Price per Wafer</td>
                          <td>{{ probe.pricePerWafer }} USD</td>
                        </tr>
                        <tr>
                          <td>Units per Wafer</td>
                          <td>{{ probe.unitsPerWafer }}</td>
                        </tr>
                        <tr>
                          <td>Price per 10</td>
                          <td>{{ probe.pricePer10 }} </td>
                        </tr>
                      </tbody>
                    </v-table>
                  </v-col>
                  <v-col cols="2" class="pa-0 ma-0"></v-col>
                  <v-col cols="5" class="pa-0 ma-0">
                    <v-table class="pa-0 ma-0" style="background-color: transparent;">
                      <tbody class="pa-0 ma-0">
                        <tr>
                          <td>Other Pricing Information</td>
                          <td>{{ probe.otherPricing }}
                          </td>
                        </tr>
                      </tbody>
                    </v-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-window-item>
          </v-window>
        </v-card-text>
      </v-card>
    </template>
  </v-list-item>
</template>

<script>
import FormattedTimestamp from "./FormattedTimestamp.vue";

export default {
  components: {
    FormattedTimestamp
  },
  emits: ["collapse"],
  props: {
    probe: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    tab: null,
  }),
  methods: {
    collapseItem() {
      this.$emit("collapse");
    }
  }
};
</script>
