
<template>
  <v-form validate-on="save" @submit.prevent="save">
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field v-model="formData.firstName" label="First Name"></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field v-model="formData.lastName" label="Last Name"></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field v-model="formData.email" label="E-Mail" :rules="emailRules"></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field v-model="formData.password" :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="PasswordRules" :type="showPassword ? 'text' : 'password'" name="input-10-1" label="Password"
          @click:append-inner="showPassword = !showPassword" autocomplete="current-password"></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-select v-if="canChangeRole()" v-model="formData.role" label="Role" :items="roles"></v-select>
        <v-select v-if="!canChangeRole()" disabled v-model="formData.role" label="Role" :items="roles"></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn type="submit" class="mr-12">Save</v-btn>
        <v-btn @click="cancel" class="ml-12">Cancel</v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>

import { useAuthStore } from "../stores/auth.store.js"

export default {
  emits: ["save", "cancel"],
  props: {
    user: {
      type: Object,
      required: true
    },
    allowEmptyPassword: {
      type: Boolean,
      required: true
    }
  },
  data: vm => ({
    formData: {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
    },
    showPassword: false,
    emailRules: [
      email => vm.checkEmail(email),
    ],
    PasswordRules: [
      password => vm.checkPassword(password),
    ],
    timeout: null,
    formResult: {
      errorMsg: ''
    },
    role: '',
    roles: ['GUEST', 'VIEWER', 'EDITOR', 'ADMIN'],
    currentUser: null,
    isAdmin: false
  }),
  mounted() {
    const authStore = useAuthStore();

    this.reset();
    this.currentUser = authStore.user
    this.isAdmin = this.currentUser.role.toUpperCase() == "ADMIN";
  },
  methods: {
    canChangeRole() {
      return this.isAdmin && this.currentUser && this.user && this.currentUser.id != this.user.id
    },
    async save(event) {
      const results = await event
      if (results.valid) {
        this.$emit('save', this.formData);
      }
    },
    async cancel() {
      this.reset();
      this.$emit('cancel');
    },
    async reset() {
      this.formData.firstName = this.user.firstName;
      this.formData.lastName = this.user.lastName;
      this.formData.email = this.user.email;
      this.formData.password = this.user.password;
      this.formData.role = this.user.role;
    },
    async checkEmail(email) {
      return new Promise(resolve => {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (!email) return resolve('Please enter your E-Mail.')
          if (email.indexOf('@') === -1) return resolve('Please enter a valid E-Mail.')

          return resolve(true)
        }, 100)
      })
    },
    async checkPassword(password) {
      return new Promise(resolve => {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (!this.allowEmptyPassword && !password) return resolve('Please enter your password.')

          return resolve(true)
        }, 100)
      })
    },
  }
}
</script>
