
var restV1Url = ''
var dataBaseUrl = ''

if (process.env.NODE_ENV === 'production') {
  restV1Url = 'https://alphorn.cloud/api/v1'
  dataBaseUrl = 'mongodb://alphorn:HGUIYX53R2EWC97QHDEENWN@mongodb:27017/spmProbeDatabase?authMechanism=DEFAULT'
}
else {
  restV1Url = 'http://localhost:3000/api/v1'
  dataBaseUrl = 'mongodb://127.0.0.1:27017/spmProbeDatabase'
}

const backendSettings = {
  port: 3000,
  dataBaseUrl: dataBaseUrl,
  restV1Url: restV1Url,
  restV1Headers: ["Access-Control-Allow-Headers",
    "x-access-token, Origin, Content-Type, Accept"],
  adminUser: {
    firstName: "Daniel",
    lastName: "Friedrich",
    email: "friedrich@nanosurf.com",
    password: "passwd",
    role: "ADMIN"
  }
}

const frontendSettings = {
  restV1Url: restV1Url,
  restV1Headers: {
    //    'Access-Control-Allow-Origin': '*', //to be removed!
    'Content-type': 'application/json'
  }
}

module.exports = {
  frontendSettings: frontendSettings,
  backendSettings: backendSettings,
};
