import { defineStore } from 'pinia';
import router from "../router";
import { login as authLogin, logout as authLogout, storeUserInfo as authStoreUserInfo, loadUserInfo as authLoadUserInfo } from '../components/auth.script';

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    user: authLoadUserInfo(),
    returnUrl: null
  }),
  actions: {
    async login(email, password) {
      const response = await authLogin({
        email: email,
        password: password
      });

      if (response.data.accessToken) {
        this.user = response.data;
        authStoreUserInfo(response.data);
        this.formData = {
          email: "",
          password: ""
        };
        console.log("login() OK")
        router.push("/probes");
        return { message: "" };
      }
      else {
        console.log("login() Failed")
        return response.data
      }
    },
    logout() {
      this.user = null;
      authLogout();
      router.push('/login');
    },
    update(newUserInfo) {
      this.user.firstName = newUserInfo.firstName;
      this.user.lastName = newUserInfo.lastName;
      this.user.email = newUserInfo.email;
      authStoreUserInfo(this.user)
    }
  }
});