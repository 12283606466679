
<script setup>

import { useAuthStore } from "../stores/auth.store.js"
import UserEdit from "@/components/UserEdit.vue";

const authStore = useAuthStore();
</script>

<template>
  <v-container style="max-width: 1200px;">
    <div class="profile py-6">
      <h1>User Profile</h1>
    </div>
    <UserEdit :user="authStore.user" @save="saveUser" :allow-empty-password="true"></UserEdit>

  </v-container>
  <v-divider></v-divider>
  <v-container v-if="isAdmin" style="max-width: 1200px;">
    <div class="user-management  py-6">
      <h1>User Management</h1>
    </div>
    <v-list v-for=" item in users" :key="item.id" lines="three">

      <v-list-item :title="item.title">

        <template v-slot:title>
          {{ item.firstName }}
          {{ item.lastName }}
        </template>
        <template v-slot:subtitle>
          {{ item.email }}
          <v-chip class="ms-0" color="green" label size="small">
            {{ item.role }}
          </v-chip>

        </template>

        <template v-slot:append>
          <v-btn color="grey-lighten-1" icon="mdi-pencil" variant="text" @click="showEditUserDialog(item)"></v-btn>
          <v-btn color="grey-lighten-1" icon="mdi-delete" variant="text" @click="showDeleteUserDialog(item)"></v-btn>
        </template>
      </v-list-item>


      <template v-slot:title="{ firstName, lastName }">
        {{ firstName }}
        {{ lastName }}
      </template>
      <template v-slot:subtitle="{ email, role }">
        <div v-html="email"></div>
        <div v-html="role"></div>
      </template>
    </v-list>
    <v-btn @click="addUser">Add User</v-btn>
  </v-container>
  <v-row justify="center">

    <v-dialog v-model="newUserDialog" persistent width="auto">
      <v-container>
        <v-card>
          <v-card-text>
            <h1 class="py-6">Add User</h1>
            <UserEdit :user="selected" @save="saveNewUser" @cancel="newUserDialog = false" :allow-empty-password="false">
            </UserEdit>
          </v-card-text>
        </v-card>
      </v-container>
    </v-dialog>

    <v-dialog v-model="editUserDialog" persistent width="auto">
      <v-container>
        <v-card>
          <v-card-text>
            <h1 class="py-6">Edit User</h1>
            <UserEdit :user="selected" @save="updateUser" @cancel="editUserDialog = false" :allow-empty-password="true">
            </UserEdit>
          </v-card-text>
        </v-card>
      </v-container>
    </v-dialog>

    <v-dialog v-model="deleteUserDialog" persistent width="auto">
      <v-container>
        <v-card>
          <v-card-title class="text-h5">
            Delete User {{ selected.firstName }} {{ selected.lastName }}
          </v-card-title>
          <v-card-text>Are you sure?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green-darken-1" variant="text" @click="deleteUserDialog = false">
              No
            </v-btn>
            <v-btn color="red-darken-1" variant="text" @click="deleteUser">
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-dialog>


  </v-row>
</template>

<script>

import axios from "axios";


import { authHeader } from "../components/auth.script"
import { frontendSettings as settings } from "../../settings";

export default {
  data: vm => ({
    editUserDialog: false,
    newUserDialog: false,
    deleteUserDialog: false,
    isAdmin: false,
    users: {},
    selected: {}
  }),
  mounted() {
    const authStore = useAuthStore();
    this.isAdmin = authStore.user.role.toLowerCase() == "admin";
    this.cancel();
    if (this.isAdmin) {
      this.fetchUsers();
    }
  },
  methods: {
    async fetchUsers() {
      try {
        const config = {
          headers: authHeader()
        };
        const response = await axios.get(settings.restV1Url + "/user/", config);
        const authStore = useAuthStore();
        const currentUserId = authStore.user.id
        function checkId(u) {
          return u._id != currentUserId;
        }
        this.users = response.data.filter(checkId);
      } catch (error) {
        console.error(error);
      }
    },
    showNewUserDialog() {
      this.selected = {
        id: null,
        email: '',
        firstName: '',
        lastName: '',
        password: '',
      }
      this.newUserDialog = true;
    },
    showEditUserDialog(user) {
      this.selected = user
      this.editUserDialog = true
    },
    showDeleteUserDialog(user) {
      this.selected = user
      this.deleteUserDialog = true
    },

    async saveUser(data) {
      let request = {
        url: settings.restV1Url + "/auth/",
        method: "post",
        headers: authHeader(),
        data: JSON.stringify(data)
      };

      try {
        const response = await axios(request);
        console.log("saveUser() response: " + JSON.stringify(response.data))
        const authStore = useAuthStore();
        authStore.update(data)
      }
      catch (error) {
        console.log("saveUser() error: " + JSON.stringify(error))
        console.log(JSON.stringify(error.response))
      }
      this.newUserDialog = false;
      this.fetchUsers()
    },

    cancel() {
      const authStore = useAuthStore();
      this.email = authStore.user.email
      this.firstName = authStore.user.firstName
      this.lastName = authStore.user.lastName
      this.role = authStore.user.role
    },
    async updateUser(data) {

      let request = {
        url: settings.restV1Url + "/user/" + this.selected._id,
        method: "put",
        headers: authHeader(),
        data: JSON.stringify(data)
      };

      try {
        await axios(request);
      }
      catch (error) {
        console.log("saveUser() error: " + JSON.stringify(error))
        console.log(JSON.stringify(error.response))
      }
      this.editUserDialog = false;
      this.fetchUsers()
    },
    async deleteUser() {
      let request = {
        url: settings.restV1Url + "/user/" + this.selected._id,
        method: "delete",
        headers: authHeader()
      };

      try {
        await axios(request);
      }
      catch (error) {
        console.log("saveUser() error: " + JSON.stringify(error))
        console.log(JSON.stringify(error.response))
      }
      this.newUserDialog = false;
      this.fetchUsers()

      this.deleteUserDialog = false
      this.fetchUsers()
    },
    addUser() {
      this.selected = {
        email: '',
        firstName: '',
        lastName: '',
        password: ''
      }
      this.newUserDialog = true
    },
    async saveNewUser(data) {

      let request = {
        url: settings.restV1Url + "/user/",
        method: "post",
        headers: authHeader(),
        data: JSON.stringify(data)
      };

      try {
        await axios(request);
      }
      catch (error) {
        console.log("saveUser() error: " + JSON.stringify(error))
        console.log(JSON.stringify(error.response))
      }
      this.fetchUsers()
      this.newUserDialog = false
    },
  }
}
</script>
