import { createRouter, createWebHashHistory } from "vue-router";
import { useAuthStore } from "../stores/auth.store.js"

import LoginView from "../views/LoginView.vue";
import ProfileView from "../views/ProfileView.vue";
import AboutView from "../views/AboutView.vue";
import ProbesView from "../views/ProbesView.vue";
import ProbesViewDetailedTable from "../views/ProbesViewDetailedTable.vue";
import ChangelogView from "../views/ChangelogView.vue";

const routes = [
  {
    path: "/",
    name: "login",
    component: LoginView,
    meta: {
      title: 'Login',
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfileView,
    meta: {
      title: 'Profile',
    },
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
    meta: {
      title: 'About',
    },
  },
  {
    path: "/probes",
    name: "probes",
    component: ProbesView,
    meta: {
      title: 'Probes',
    },
  },
  {
    path: "/probesDetailedTable",
    name: "probesDetailedTable",
    component: ProbesViewDetailedTable,
    meta: {
      title: 'Probes Detailed Table',
    },
  },
  {
    path: "/changelog",
    name: "changelog",
    component: ChangelogView,
    meta: {
      title: 'Changelog',
    },
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = 'Alphorn - ' + to.meta?.title ?? 'Alphorn - The Cantilever Database'
  const authStore = useAuthStore();
  const isAuthenticated = authStore.user != null;
  if (to.name !== 'login' && !isAuthenticated) next({ name: 'login' })
  else next()
})

export default router;
