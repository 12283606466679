<template>
  <v-tooltip>
    <span>{{ formattedDate }}</span>
    <template v-slot:activator="{ props }">
      <span v-bind="props">{{ timePassed }}</span>
    </template>
  </v-tooltip>
</template>

<script>
import moment from 'moment';

export default {
  name: 'FormattedTimestamp',
  props: {
    timestamp: {
      type: [Number, String],
      required: true
    }
  },
  computed: {
    timePassed() {
      const date = moment(this.timestamp);
      const diffInDays = moment().diff(date, 'days');
      if (diffInDays > 1) {
        return `${diffInDays} days ago`;
      } else {
        return `${date.fromNow()}`;
      }
    },
    formattedDate() {
      return moment(this.timestamp).format('MMM D, YYYY h:mm a')
    }
  }
};
</script>
