
<template>
  <v-dialog v-model="show" scrollable width="auto" persistent>
    <v-form validate-on="save" @submit.prevent="save">
      <v-card height="87vh">

        <v-card-title>
          <span class="text-h5">{{ title }}: {{ formData.modelNameShort }} - {{ formData.manufacturer }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>

          <!-- GENERAL information -->
          <v-container>
            <v-row>
              <v-col class="mt-4" cols="12" md="6">
                <span class="text-h6"> General </span>
              </v-col>
              <v-col class="mt-4" cols="12" md="1">
                <span style="font-size: 24pt;">
                  <v-icon v-if="formData.favorite" @click="formData.favorite = false" icon="mdi-star"></v-icon>
                  <v-icon v-else @click="formData.favorite = true" icon="mdi-star-outline"></v-icon>
                </span>
              </v-col>
              <v-col class="mt-4" cols="12" md="2">
                <v-text-field v-model="formData.sortingWeight" label="Sort Priority"></v-text-field>
              </v-col>
              <v-col class="mt-4" cols="12" md="3">
                <v-select v-model="formData.status" label="Status" :items="['INCOMPLETE', 'HIDDEN', 'ACTIVE']"></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="formData.modelNameShort" label="Model Name short"></v-text-field>
                <v-text-field v-model="formData.modelNameLong" label="Model Name long"></v-text-field>
                <v-text-field v-model="formData.manufacturer" label="Manufacturer"></v-text-field>
                <v-text-field v-model="formData.type" label="Type"></v-text-field>
                <v-text-field v-model="formData.applications" label="Applications"></v-text-field>
                <v-text-field v-model="formData.specSheet" label="specSheet"></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-img class="mx-auto" :width="300" v-if="fileCantileverImage != null" :src="fileCantileverImageUrl">
                </v-img>
                <v-img class="mx-auto" :width="300" v-if="fileCantileverImage == null"
                  :src="require('../assets/dummyCantileverPicture.png')">
                </v-img>
                <v-file-input class="mx-auto" style="width: 300px;" prepend-icon=" mdi-camera" accept="image/*"
                  @change="onFileCantileverImageChanged" @click:clear="onFileCantileverImageReset" show-size
                  variant="solo" label="Cantilever Image"></v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-textarea v-model="formData.description" auto-grow label="Description"></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea v-model="formData.comments" auto-grow label="Comment"></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>

          <!-- Traits information -->
          <v-container>
            <v-row>
              <v-col class="mt-4" cols="12" md="12">
                <span class="text-h6">Probe Traits</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-4" cols="12" md="3">
                <span>Auto Laser Align</span>
              </v-col>
              <v-col cols="12" md="5">
                <v-select v-model="formData.autoAlignStrategy" label="Auto Align Strategy"
                  :items="['STANDARD', 'SEARCH_REFLECTING_EDGES']"></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-select v-model="formData.autoAlignStatus" label="Auto Align Status"
                  :items="['UNKNOWN', 'VALIDATED', 'UNSUPPORTED']"></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-4" cols="12" md="3">
                <span>Deflection Sensitivity</span>
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field v-model="formData.deflectionSensitivityChi" label="Chi"></v-text-field>
              </v-col>

            </v-row>
            <v-row>
              <v-col class="mt-4" cols="12" md="3">
                <span>Spring Constant</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.springConstantNom" label="Nominal" suffix="N/m"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.springConstantMin" label="Min" suffix="N/m"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.springConstantMax" label="Max" suffix="N/m"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-4" cols="12" md="3">
                <span>Resonance Frequency Air</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.resonanceFrequencyAirNom" label="Nominal" suffix="kHz"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.resonanceFrequencyAirMin" label="Min" suffix="kHz"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.resonanceFrequencyAirMax" label="Max" suffix="kHz"></v-text-field>
              </v-col>
              <v-col class="mt-4" cols="12" md="3">
                <span>Quality Factor Air</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.qualityFactorAirNom" label="Nominal"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.qualityFactorAirMin" label="Min"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.qualityFactorAirMax" label="Max"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="mt-4" cols="12" md="3">
                <span>Resonance Frequency Liquid</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.resonanceFrequencyLiquidNom" label="Nominal" suffix="kHz"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.resonanceFrequencyLiquidMin" label="Min" suffix="kHz"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.resonanceFrequencyLiquidMax" label="Max" suffix="kHz"></v-text-field>
              </v-col>
              <v-col class="mt-4" cols="12" md="3">
                <span>Quality Factor Liquid</span>
              </v-col>
              <v-col cols="12" md="3" class="py-0">
                <v-text-field v-model="formData.qualityFactorLiquidNom" label="Nominal"></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="py-0">
                <v-text-field v-model="formData.qualityFactorLiquidMin" label="Min"></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="py-0">
                <v-text-field v-model="formData.qualityFactorLiquidMax" label="Max"></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <!-- Laser Limits -->
          <v-container>
            <v-row>
              <v-col class="mt-4" cols="12" md="3">
                <span>PTE Laser AC Power Default value</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.pteLaserAcPowerAirDefault" label="Air" suffix="V"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.pteLaserAcPowerLiquidDefault" label="Liquid" suffix="V"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.pteLaserAcPowerVacuumDefault" label="Vacuum" suffix="V"></v-text-field>
              </v-col>
              <v-col class="mt-4" cols="12" md="3">
                <span>PTE Laser DC Power Max</span>
              </v-col>
              <v-col cols="12" md="3" class="py-0">
                <v-text-field v-model="formData.pteLaserDcPowerAirMax" label="Air" suffix="W"></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="py-0">
                <v-text-field v-model="formData.pteLaserDcPowerLiquidMax" label="Liquid" suffix="W"></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="py-0">
                <v-text-field v-model="formData.pteLaserDcPowerVacuumMax" label="Vacuum" suffix="W"></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <!-- Fitting information -->
          <v-divider></v-divider>
          <v-container>
            <v-row>
              <v-col class="mt-4" cols="12" md="12">
                <span class="text-h6">Fitting parameters</span>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="mt-4" cols="12" md="3">
                <span>Frequency Sweep Fitting Air</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.frequencySweepFittingLowerBoundAir" label="Lower Bound"
                  suffix="kHz"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.frequencySweepFittingUpperBoundAir" label="Upper Bound"
                  suffix="kHz"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.frequencySweepFittingDatapointsAir" label="Datapoints"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-4" cols="12" md="3">
                <span>Frequency Sweep Fitting Liquid</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.frequencySweepFittingLowerBoundLiquid" label="Lower Bound"
                  suffix="kHz"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.frequencySweepFittingUpperBoundLiquid" label="Upper Bound"
                  suffix="kHz"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.frequencySweepFittingDatapointsLiquid" label="Datapoints"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="mt-4" cols="12" md="3">
                <span>Frequency Sweep Fitting Vacuum</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.frequencySweepFittingLowerBoundVacuum" label="Lower Bound"
                  suffix="kHz"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.frequencySweepFittingUpperBoundVacuum" label="Upper Bound"
                  suffix="kHz"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.frequencySweepFittingDatapointsVacuum" label="Datapoints"></v-text-field>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <v-row>
              <v-col class="mt-4" cols="12" md="3">
                <span>Thermal Tune Fitting Air</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.thermalTuneFittingLowerBoundAir" label="Lower Bound"
                  suffix="kHz"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.thermalTuneFittingUpperBoundAir" label="Upper Bound"
                  suffix="kHz"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="mt-4" cols="12" md="3">
                <span>Thermal Tune Fitting Liquid</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.thermalTuneFittingLowerBoundLiquid" label="Lower Bound"
                  suffix="kHz"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.thermalTuneFittingUpperBoundLiquid" label="Upper Bound"
                  suffix="kHz"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="mt-4" cols="12" md="3">
                <span>Thermal Tune Fitting Vacuum</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.thermalTuneFittingLowerBoundVacuum" label="Lower Bound"
                  suffix="kHz"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.thermalTuneFittingUpperBoundVacuum" label="Upper Bound"
                  suffix="kHz"></v-text-field>
              </v-col>
            </v-row>
          </v-container>



          <!-- Cantilever information -->
          <v-divider></v-divider>
          <v-container>
            <v-row>
              <v-col class="mt-4" cols="12" md="12">
                <span class="text-h6">Cantilever Properties</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-4" cols="12" md="3">
                <span>Length</span>
              </v-col>
              <v-col cols="12" md="3" class="py-0">
                <v-text-field v-model="formData.lengthNom" label="Nominal" suffix="µm"></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="py-0">
                <v-text-field v-model="formData.lengthMin" label="Min" suffix="µm"></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="py-0">
                <v-text-field v-model="formData.lengthMax" label="Max" suffix="µm"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-4" cols="12" md="3">
                <span>Width</span>
              </v-col>
              <v-col cols="12" md="3" class="py-0">
                <v-text-field v-model="formData.widthNom" label="Nominal" suffix="µm"></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="py-0">
                <v-text-field v-model="formData.widthMin" label="Min" suffix="µm"></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="py-0">
                <v-text-field v-model="formData.widthMax" label="Max" suffix="µm"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-4" cols="12" md="3">
                <span>Thickness</span>
              </v-col>
              <v-col cols="12" md="3" class="py-0">
                <v-text-field v-model="formData.thicknessNom" label="Nominal" suffix="µm"></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="py-0">
                <v-text-field v-model="formData.thicknessMin" label="Min" suffix="µm"></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="py-0">
                <v-text-field v-model="formData.thicknessMax" label="Max" suffix="µm"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3" class="py-0">
                <v-select v-model="formData.shape" label="Shape"
                  :items="['RECTANGULAR', 'TRIANGULAR', 'UNKNOWN']"></v-select>
              </v-col>
              <v-col cols="12" md="3" class="py-0">
                <v-text-field v-model="formData.material" label="Material"></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="py-0">
                <v-text-field v-model="formData.reflexCoatingMaterial" label="Reflex Coating Material"></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="py-0">
                <v-text-field v-model="formData.reflexCoatingType" label="Reflex Coating Type"></v-text-field>
              </v-col>
            </v-row>
          </v-container>




          <!-- Tip information -->
          <v-divider></v-divider>
          <v-container>
            <v-row>
              <v-col class="mt-4" cols="12" md="6">
                <span class="text-h6">Tip Properties</span>
                <v-select v-model="formData.tipShape" label="Tip Shape"
                  :items="['UNKNOWN', 'CONE', '2-SIDED', '3-SIDED', '4-SIDED', '8-SIDED', 'NO TIP']"></v-select>

                <v-text-field v-model="formData.tipMaterial" label="tipMaterial"></v-text-field>

                <v-text-field v-model="formData.tipCoating" label="tipCoating"></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-img class="mx-auto" :width="300" v-if="fileTipImage != null" :src="fileTipImageUrl">
                </v-img>
                <v-img class="mx-auto" :width="300" v-if="fileTipImage == null"
                  :src="require('../assets/dummyCantileverPicture.png')">
                </v-img>
                <v-file-input class="mx-auto" style="width: 300px;" prepend-icon=" mdi-camera" accept="image/*"
                  @change="onFileTipImageChanged" @click:clear="onFileTipImageReset" show-size variant="solo"
                  label="Tip Image"></v-file-input>
              </v-col>

            </v-row>
            <v-row>
              <v-col class="mt-4" cols="12" md="3">
                <span>tipRadiusNom</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.tipRadiusNom" label="Nominal" suffix="nm"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.tipRadiusMin" label="Min" suffix="nm"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.tipRadiusMax" label="Max" suffix="nm"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-4" cols="12" md="3">
                <span>tipHeightNom</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.tipHeightNom" label="Nominal" suffix="µm"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.tipHeightMin" label="Min" suffix="µm"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.tipHeightMax" label="Max" suffix="µm"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-4" cols="12" md="3">
                <span>tipFrontAngleNom</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.tipFrontAngleNom" label="Nominal" suffix="°"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.tipFrontAngleMin" label="Min" suffix="°"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.tipFrontAngleMax" label="Max" suffix="°"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-4" cols="12" md="3">
                <span>tipBackAngleNom</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.tipBackAngleNom" label="Nominal" suffix="°"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.tipBackAngleMin" label="Min" suffix="°"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.tipBackAngleMax" label="Max" suffix="°"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-4" cols="12" md="3">
                <span>tipSideAngleNom</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.tipSideAngleNom" label="Nominal" suffix="°"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.tipSideAngleMin" label="Min" suffix="°"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.tipSideAngleMax" label="Max" suffix="°"></v-text-field>
              </v-col>
            </v-row>

          </v-container>


          <!-- Spike information -->
          <v-divider></v-divider>
          <v-container>
            <v-row>
              <v-col class="mt-4" cols="12" md="12">
                <span class="text-h6">Spike Properties</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-4" cols="12" md="3">
                <span>spikeRadiusNom</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.spikeRadiusNom" label="Nominal" suffix="nm"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.spikeRadiusMin" label="Min" suffix="nm"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.spikeRadiusMax" label="Max" suffix="nm"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-4" cols="12" md="3">
                <span>spikeHeightNom</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.spikeHeightNom" label="Nominal" suffix="µm"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.spikeHeightMin" label="Min" suffix="µm"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.spikeHeightMax" label="Max" suffix="µm"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-4" cols="12" md="3">
                <span>spikeTiltNom</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.spikeTiltNom" label="Nominal" suffix="°"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.spikeTiltMin" label="Min" suffix="°"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.spikeTiltMax" label="Max" suffix="°"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-4" cols="12" md="3">
                <span>spikeHalfAngleNom</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.spikeHalfAngleNom" label="Nominal" suffix="°"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.spikeHalfAngleMin" label="Min" suffix="°"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.spikeHalfAngleMax" label="Max" suffix="°"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
              </v-col>
              <v-col cols="12" md="3">
              </v-col>
              <v-col cols="12" md="3">
                <v-select v-model="formData.spikeShape" label="Spike Shape"
                  :items="['NONE', 'UNKNOWN', 'CONE', 'POST', '4-SIDED', '8-SIDED', 'TWIN TIP', 'NO TIP']"></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.spikeMaterial" label="spikeMaterial"></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <!-- Pricing information -->
          <v-divider></v-divider>
          <v-container>
            <v-row>
              <v-col class="mt-4" cols="12" md="3">
                <span>Pricing</span>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.pricePer10" label="Price per 10"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.pricePerWafer" label="Price per Wafer"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="formData.unitsPerWafer" label="Units per Wafer"></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field v-model="formData.otherPricing" label="Other Pricing Information"></v-text-field>
              </v-col>
            </v-row>
          </v-container>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-darken-1" variant="text" @click="cancel">Close</v-btn>
          <v-btn color="blue-darken-1" variant="text" type="submit" class="mr-12">Save</v-btn>
        </v-card-actions>

      </v-card>
    </v-form>
  </v-dialog>
</template>



<script>
const { Buffer } = require('buffer')

export default {
  emits: ['update:showFlag', 'save', 'cancel'],
  props: {
    probe: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    showFlag: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    formData: {},

    fileCantileverImage: null,
    fileCantileverImageChanged: false,
    fileCantileverImageUrl: null,

    fileTipImage: null,
    fileTipImageChanged: false,
    fileTipImageUrl: null,
  }),
  updated() {
    if (this.showFlag == true) {
      this.reset();
    }
  },
  computed: {
    show: {
      get() {
        return this.showFlag
      },
      set(value) {

        this.$emit("update:showFlag", value);
      }
    }
  },
  methods: {
    async save(event) {
      console.log("save()")
      const results = await event
      if (results.valid) {
        if (this.fileCantileverImageChanged) {
          this.formData.fileCantileverImage = this.fileCantileverImage
        }
        if (this.fileTipImageChanged) {
          this.formData.fileTipImage = this.fileTipImage
        }
        this.$emit('save', this.formData);
      }
    },
    async cancel() {
      this.reset();
      this.$emit('cancel');
    },
    reset() {
      console.log("reset() " + this.probe.modelNameShort)
      this.formData = { ... this.probe };
      this.fileCantileverImageChanged = false
      if (this.probe && this.probe.cantileverPicture) {
        this.fileCantileverImage = this.base64toFile(this.probe.cantileverPicture.originalName, this.probe.cantileverPicture.contentType, this.probe.cantileverPicture.data)
        this.fileCantileverImageUrl = URL.createObjectURL(this.fileCantileverImage);
      }
      else {
        this.fileCantileverImage = null
        this.fileCantileverImageUrl = null
      }

      this.fileTipImageChanged = false
      if (this.probe && this.probe.cantileverTipPicture) {
        this.fileTipImage = this.base64toFile(this.probe.cantileverTipPicture.originalName, this.probe.cantileverTipPicture.contentType, this.probe.cantileverTipPicture.data)
        this.fileTipImageUrl = URL.createObjectURL(this.fileTipImage);
      }
      else {
        this.fileTipImage = null
        this.fileTipImageUrl = null
      }
    },
    onFileCantileverImageChanged(event) {
      console.log("onFileCantileverImageChanged()")
      this.fileCantileverImage = event.target.files[0]
      this.fileCantileverImageChanged = true
      this.fileCantileverImageUrl = URL.createObjectURL(this.fileCantileverImage);
    },
    onFileCantileverImageReset() {
      console.log("onFileCantileverImageReset()")
      this.fileCantileverImage = this.probe.cantileverPicture
      this.fileCantileverImageChanged = false
      this.fileCantileverImage = this.base64toFile(this.fileCantileverImage.originalName, this.fileCantileverImage.contentType, this.fileCantileverImage.data)
      this.fileCantileverImageUrl = URL.createObjectURL(this.fileCantileverImage);
    },
    onFileTipImageChanged(event) {
      console.log("onFileTipImageChanged()")
      this.fileTipImage = event.target.files[0]
      this.fileTipImageChanged = true
      this.fileTipImageUrl = URL.createObjectURL(this.fileTipImage);
    },
    onFileTipImageReset() {
      console.log("onFileTipImageReset()")
      this.fileTipImage = this.probe.cantileverPicture
      this.fileTipImageChanged = false
      this.fileCantileverImage = this.base64toFile(this.fileTipImage.originalName, this.fileTipImage.contentType, this.fileTipImage.data)
      this.fileTipImage = URL.createObjectURL(this.fileTipImage);
    },
    base64toFile(name, type, data) {
      //   console.log("base64toFile() name: " + name)
      //   console.log("base64toFile() type: " + type)
      //   console.log("base64toFile() data: " + data)
      const buffer = Buffer.from(data, 'base64');
      return new File([buffer], name, { type });
    }
  }
}
</script>
