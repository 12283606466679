<script setup>
import ListItemProbeExpanded from "@/components/ListItemProbeExpanded.vue";

</script>
<template>
  <div class="probes">
    <v-container justify="center">
      <h1 class="mb-10">AFM Cantilever list</h1>
      <v-btn v-if="canCreate()" @click="showNewProbeDialog">Add</v-btn>
      <v-btn v-if="canExport()" @click="showExportDialog" download="spmProbes.xml">Create DB revision</v-btn>
      <v-btn v-if="canExport()" @click="fixAndUpdate">fixAndUpdate DB</v-btn>
    </v-container>
    <v-container>
      <ProbeEdit v-model:showFlag="showAddEditProbeDialogFlag" v-model:title="addEditProbeDialogTitle"
        v-model:probe="selectedProbe" @cancel="showAddEditProbeDialogFlag = false" @save="saveSelectedProbe">
      </ProbeEdit>
      <v-text-field v-model="searchString" clearable hide-details="auto" label="Search"></v-text-field>

      <EasyDataTable alternating :headers="headers" :items="probes" :search-value="searchString" :sort-by="sortBy"
        :sort-type="sortType" multi-sort table-class-name="customize-table" no-hover>

        <template #header-favorite="header">
          <v-icon icon="mdi-star"></v-icon>
        </template>
        <template #header-sortingWeight="header">
          <v-icon icon="mdi-priority-high"></v-icon>
        </template>

        <template #item-favorite="{ favorite }">
          <span v-if="favorite">
            <v-icon icon="mdi-star"></v-icon>
          </span>
          <span v-else>
            <v-icon icon="mdi-star-outline"></v-icon>
          </span>
        </template>
        <template #item-userActions="item">
          <v-btn v-if="canEdit()" color="grey-lighten-1" icon="mdi-pencil" variant="text"
            @click="showEditProbeDialog(item)"></v-btn>
          <v-btn v-if="canDelete()" color="grey-lighten-1" icon="mdi-delete" variant="text"
            @click="showDeleteProbeDialog(item)"></v-btn>
        </template>

      </EasyDataTable>
    </v-container>

    <v-row justify="center">



      <v-dialog v-model="showDeleteProbeDialogFlag" persistent width="auto">
        <v-container>
          <v-card>
            <v-card-title class="text-h5">
              Delete Probe {{ selectedProbe.title }}
            </v-card-title>
            <v-card-text>Are you sure?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green-darken-1" variant="text" @click="showDeleteProbeDialogFlag = false">
                No
              </v-btn>
              <v-btn color="red-darken-1" variant="text" @click="deleteSelectedProbe">
                Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-container>
      </v-dialog>

      <v-dialog v-model="showExportDialogFlag" persistent width="auto">
        <v-container>
          <v-card>
            <v-card-title class="text-h5">
              Export Probes to XML for nanosurf Studio?
            </v-card-title>
            <v-card-text>
              <p style="margin: 10px 0; padding: 5px;">
                This will generate an XML file with a new revision number.<br>
                To be used by Nanosurf Studio.
              </p>
              <p style="margin: 10px 0; padding: 5px;">
                Are you sure?
              </p>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green-darken-1" variant="text" @click="showExportDialogFlag = false">
                No
              </v-btn>
              <v-btn color="red-darken-1" variant="text" @click="exportProbes">
                Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-container>
      </v-dialog>

    </v-row>

  </div>
</template>


<script>


import axios from 'axios';
import { authHeader } from '../components/auth.script.js'
import { useAuthStore } from "../stores/auth.store.js"

import { frontendSettings as settings } from "../../settings";
import ProbeEdit from "@/components/ProbeEdit.vue";

export default {
  data: () => ({
    showAddEditProbeDialogFlag: false,
    showDeleteProbeDialogFlag: false,
    showExportDialogFlag: false,
    exportXmlFileUrl: null,
    exportXmlFileName: "ExportedSpmProbes.xml",
    selectedProbe: null,
    addEditProbeDialogTitle: "",
    searchString: "",
    sortBy: ["favorite", "sortingWeight"],
    sortType: ["desc", "desc"],
    headers: [
      { text: "uuid", value: "uuid", sortable: true },
      { text: "sortingWeight", value: "sortingWeight", sortable: true },
      { text: "favorite", value: "favorite", sortable: true },
      { text: "status", value: "status", sortable: true },
      // { text: "revision", value: "revision", sortable: true },
      // { text: "updatedBy", value: "updatedBy", sortable: true },
      // { text: "createdBy", value: "createdBy", sortable: true },
      { text: "manufacturer", value: "manufacturer", sortable: true },
      { text: "modelNameShort", value: "modelNameShort", sortable: true },
      // { text: "modelNameLong", value: "modelNameLong", sortable: true },
      // { text: "specSheet", value: "specSheet", sortable: true },
      // { text: "type", value: "type", sortable: true },
      // { text: "description", value: "description", sortable: true },
      // { text: "applications", value: "applications", sortable: true },
      // { text: "comments", value: "comments", sortable: true },
      // { text: "pricePerWafer", value: "pricePerWafer", sortable: true },
      // { text: "unitsPerWafer", value: "unitsPerWafer", sortable: true },
      // { text: "pricePer10", value: "pricePer10", sortable: true },
      // { text: "otherPricing", value: "otherPricing", sortable: true },
      { text: "springConstantNom", value: "springConstantNom", sortable: true },
      { text: "springConstantMin", value: "springConstantMin", sortable: true },
      { text: "springConstantMax", value: "springConstantMax", sortable: true },
      { text: "resonanceFrequencyAirNom", value: "resonanceFrequencyAirNom", sortable: true },
      { text: "resonanceFrequencyAirMin", value: "resonanceFrequencyAirMin", sortable: true },
      { text: "resonanceFrequencyAirMax", value: "resonanceFrequencyAirMax", sortable: true },
      { text: "qualityFactorAirNom", value: "qualityFactorAirNom", sortable: true },
      { text: "qualityFactorAirMin", value: "qualityFactorAirMin", sortable: true },
      { text: "qualityFactorAirMax", value: "qualityFactorAirMax", sortable: true },
      { text: "resonanceFrequencyLiquidNom", value: "resonanceFrequencyLiquidNom", sortable: true },
      { text: "resonanceFrequencyLiquidMin", value: "resonanceFrequencyLiquidMin", sortable: true },
      { text: "resonanceFrequencyLiquidMax", value: "resonanceFrequencyLiquidMax", sortable: true },
      { text: "qualityFactorLiquidNom", value: "qualityFactorLiquidNom", sortable: true },
      { text: "qualityFactorLiquidMin", value: "qualityFactorLiquidMin", sortable: true },
      { text: "qualityFactorLiquidMax", value: "qualityFactorLiquidMax", sortable: true },
      // { text: "resonanceFrequencyVacuumNom", value: "resonanceFrequencyVacuumNom", sortable: true },
      // { text: "resonanceFrequencyVacuumMin", value: "resonanceFrequencyVacuumMin", sortable: true },
      // { text: "resonanceFrequencyVacuumMax", value: "resonanceFrequencyVacuumMax", sortable: true },
      // { text: "qualityFactorVacuumNom", value: "qualityFactorVacuumNom", sortable: true },
      // { text: "qualityFactorVacuumMin", value: "qualityFactorVacuumMin", sortable: true },
      // { text: "qualityFactorVacuumMax", value: "qualityFactorVacuumMax", sortable: true },
      { text: "deflectionSensitivityChi", value: "deflectionSensitivityChi", sortable: true },
      { text: "autoAlignStrategy", value: "autoAlignStrategy", sortable: true },
      { text: "autoAlignStatus", value: "autoAlignStatus", sortable: true },
      { text: "lengthNom", value: "lengthNom", sortable: true },
      { text: "lengthMin", value: "lengthMin", sortable: true },
      { text: "lengthMax", value: "lengthMax", sortable: true },
      { text: "widthNom", value: "widthNom", sortable: true },
      { text: "widthMin", value: "widthMin", sortable: true },
      { text: "widthMax", value: "widthMax", sortable: true },
      { text: "thicknessNom", value: "thicknessNom", sortable: true },
      { text: "thicknessMin", value: "thicknessMin", sortable: true },
      { text: "thicknessMax", value: "thicknessMax", sortable: true },
      { text: "shape", value: "shape", sortable: true },
      { text: "material", value: "material", sortable: true },
      // { text: "reflexCoatingMaterial", value: "reflexCoatingMaterial", sortable: true },
      // { text: "reflexCoatingType", value: "reflexCoatingType", sortable: true },
      // { text: "tipRadiusNom", value: "tipRadiusNom", sortable: true },
      // { text: "tipRadiusMin", value: "tipRadiusMin", sortable: true },
      // { text: "tipRadiusMax", value: "tipRadiusMax", sortable: true },
      // { text: "tipHeightNom", value: "tipHeightNom", sortable: true },
      // { text: "tipHeightMin", value: "tipHeightMin", sortable: true },
      // { text: "tipHeightMax", value: "tipHeightMax", sortable: true },
      // { text: "tipFrontAngleNom", value: "tipFrontAngleNom", sortable: true },
      // { text: "tipFrontAngleMin", value: "tipFrontAngleMin", sortable: true },
      // { text: "tipFrontAngleMax", value: "tipFrontAngleMax", sortable: true },
      // { text: "tipBackAngleNom", value: "tipBackAngleNom", sortable: true },
      // { text: "tipBackAngleMin", value: "tipBackAngleMin", sortable: true },
      // { text: "tipBackAngleMax", value: "tipBackAngleMax", sortable: true },
      // { text: "tipSideAngleNom", value: "tipSideAngleNom", sortable: true },
      // { text: "tipSideAngleMin", value: "tipSideAngleMin", sortable: true },
      // { text: "tipSideAngleMax", value: "tipSideAngleMax", sortable: true },
      // { text: "tipShape", value: "tipShape", sortable: true },
      // { text: "tipMaterial", value: "tipMaterial", sortable: true },
      // { text: "tipCoating", value: "tipCoating", sortable: true },
      // { text: "spikeRadiusNom", value: "spikeRadiusNom", sortable: true },
      // { text: "spikeRadiusMin", value: "spikeRadiusMin", sortable: true },
      // { text: "spikeRadiusMax", value: "spikeRadiusMax", sortable: true },
      // { text: "spikeHeightNom", value: "spikeHeightNom", sortable: true },
      // { text: "spikeHeightMin", value: "spikeHeightMin", sortable: true },
      // { text: "spikeHeightMax", value: "spikeHeightMax", sortable: true },
      // { text: "spikeTiltNom", value: "spikeTiltNom", sortable: true },
      // { text: "spikeTiltMin", value: "spikeTiltMin", sortable: true },
      // { text: "spikeTiltMax", value: "spikeTiltMax", sortable: true },
      // { text: "spikeHalfAngleNom", value: "spikeHalfAngleNom", sortable: true },
      // { text: "spikeHalfAngleMin", value: "spikeHalfAngleMin", sortable: true },
      // { text: "spikeHalfAngleMax", value: "spikeHalfAngleMax", sortable: true },
      // { text: "spikeShape", value: "spikeShape", sortable: true },
      // { text: "spikeMaterial", value: "spikeMaterial", sortable: true },
      { text: "waveModeMaxFrequency", value: "waveModeMaxFrequency", sortable: true },
      { text: "Actions", value: "userActions", sortable: false }
    ],
    probes: [],

    isAdmin: false,
    isEditor: false,
    isViewer: false,
    isGuest: false,
  }),
  components: {
    ProbeEdit
  },
  computed: {
    numberOfProbes() {
      return this.probes.length;
    },
  },
  created() {
    this.resetSelectedProbeObject();
    this.fetchProbes();

    const authStore = useAuthStore();
    this.isAdmin = authStore.user.role.toUpperCase() == "ADMIN";
    this.isEditor = authStore.user.role.toUpperCase() == "EDITOR";
    this.isViewer = authStore.user.role.toUpperCase() == "VIEWER";
    this.isGuest = authStore.user.role.toUpperCase() == "GUEST";
  },
  methods: {
    canExport() {
      return this.isAdmin;
    },
    canEdit() {
      return this.isEditor || this.isAdmin;
    },
    canCreate() {
      return this.isEditor || this.isAdmin;
    },
    canDelete() {
      return this.isAdmin;
    },

    showExportDialog() {
      this.showExportDialogFlag = true;
    },
    async exportProbes() {
      let request = {
        url: settings.restV1Url + "/spmprobes/export",
        method: "get",
        headers: authHeader(),
      };
      try {
        const response = await axios(request);
        // Create a blob from the response data
        const blob = new Blob([response.data], { type: 'text/xml' });
        // Create a temporary URL for the blob
        this.exportXmlFileUrl = URL.createObjectURL(blob);
        // Create a link and click it to initiate the download
        const link = document.createElement('a');
        link.download = this.exportXmlFileName;
        link.href = this.exportXmlFileUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        this.showExportDialogFlag = false;
      }
      catch (error) {
        console.log("exportProbes() error: " + error)
        console.log("exportProbes() error.response: " + JSON.stringify(error.response))
      }
    },
    async fetchProbes() {

      let request = {
        url: settings.restV1Url + "/spmprobes",
        method: "get",
        headers: authHeader(),
      };
      try {
        const response = await axios(request);
        this.probes = response.data;
      }
      catch (error) {
        console.log("fetchProbes() error: " + error)
        console.log("fetchProbes() error.response: " + JSON.stringify(error.response))
      }
    },
    resetSelectedProbeObject() {
      this.selectedProbe = {
        sortingWeight: 0,
        status: "INCOMPLETE",
        autoAlignStrategy: "STANDARD",
        autoAlignStatus: "UNKNOWN",
        shape: "RECTANGULAR",
        tipShape: "UNKNOWN",
        spikeShape: "NONE"
      }
    },
    showNewProbeDialog() {
      this.addEditProbeDialogTitle = "New Probe"
      this.resetSelectedProbeObject();
      this.showAddEditProbeDialogFlag = true;
    },
    showEditProbeDialog(probe) {
      console.log("showEditProbeDialog() modelNameShort: " + probe.modelNameShort)
      this.addEditProbeDialogTitle = "Edit Probe"
      this.selectedProbe = probe
      this.showAddEditProbeDialogFlag = true;
    },
    showDeleteProbeDialog(probe) {
      this.selectedProbe = probe
      this.showDeleteProbeDialogFlag = true;
    },
    async fixAndUpdate() {
      console.log("fixAndUpdate ")
      let request = {
        url: settings.restV1Url + "/spmprobes/fix_and_update",
        method: "get",
        headers: authHeader(),
      };

      try {
        const response = await axios(request);
        console.log("fixAndUpdate() response: " + JSON.stringify(response.data))

      }
      catch (error) {
        console.log("fixAndUpdate() error: " + JSON.stringify(error))
        console.log(JSON.stringify(error.response))
      }
      this.fetchProbes()
    },
    async deleteSelectedProbe() {
      console.log("deleteSelectedProbe " + this.selectedProbe.name)
      let request = {
        url: settings.restV1Url + "/spmprobes/" + this.selectedProbe.id,
        method: "delete",
        headers: authHeader(),
      };

      try {
        const response = await axios(request);
        console.log("deleteSelectedProbe() response: " + JSON.stringify(response.data))

      }
      catch (error) {
        console.log("deleteSelectedProbe() error: " + JSON.stringify(error))
        console.log(JSON.stringify(error.response))
      }
      this.selectedProbe = {}
      this.showDeleteProbeDialogFlag = false;
      this.fetchProbes()
    },
    async saveSelectedProbe(probe) {
      const fileCantileverImage = probe.fileCantileverImage;
      const fileTipImage = probe.fileTipImage;
      delete probe.fileCantileverImage;
      delete probe.fileTipImage;
      delete probe.cantileverPicture;
      delete probe.cantileverTipPicture;

      const updateProbe = this.selectedProbe && this.selectedProbe.id
      const urlAppend = updateProbe ? this.selectedProbe.id : ""
      const method = updateProbe ? "put" : "post"
      let request = {
        url: settings.restV1Url + "/spmprobes/" + urlAppend,
        method: method,
        headers: authHeader(),
        data: JSON.stringify(probe)
      };

      try {
        const response = await axios(request);
        console.log("saveSelectedProbe() response: " + JSON.stringify(response.data))

        if (fileCantileverImage != null) {
          console.log("saveSelectedProbe() fileCantileverImage: " + fileCantileverImage)
          console.log("saveSelectedProbe() fileCantileverImage.name: " + fileCantileverImage.name)
          const fd = new FormData();
          fd.append("fileCantileverImage", fileCantileverImage, fileCantileverImage.name)
          let request = {
            url: settings.restV1Url + "/spmprobes/cantilever_image/" + response.data.probe.id,
            method: "post",
            headers: {
              ...authHeader(),
              "Content-Type": "multipart/form-data"
            },
            data: fd
          };
          const responsePicture = await axios(request);
          console.log("saveSelectedProbe() responsePicture: " + JSON.stringify(responsePicture))
        }

        if (fileTipImage != null) {
          console.log("saveSelectedProbe() fileTipImage: " + fileTipImage)
          console.log("saveSelectedProbe() fileTipImage.name: " + fileTipImage.name)
          const fd = new FormData();
          fd.append("fileTipImage", fileTipImage, fileTipImage.name)
          let request = {
            url: settings.restV1Url + "/spmprobes/tip_image/" + response.data.probe.id,
            method: "post",
            headers: {
              ...authHeader(),
              "Content-Type": "multipart/form-data"
            },
            data: fd
          };
          const responsePicture = await axios(request);
          console.log("saveSelectedProbe() responsePicture: " + JSON.stringify(responsePicture))
        }
      }
      catch (error) {
        console.log("saveSelectedProbe() error: " + JSON.stringify(error))
        console.log(JSON.stringify(error.response))
      }
      this.showAddEditProbeDialogFlag = false;
      this.fetchProbes()
    }
  }
}

</script>
<style>
.customize-table {
  --easy-table-border: 1px solid #445269;
  --easy-table-row-border: 1px solid #445269;

  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-font-color: #c1cad4;
  --easy-table-header-background-color: #2d3a4f;

  --easy-table-header-item-padding: 10px 15px;

  --easy-table-body-even-row-font-color: #fff;
  --easy-table-body-even-row-background-color: #4c5d7a;

  --easy-table-body-row-font-color: #c0c7d2;
  --easy-table-body-row-background-color: #2d3a4f;
  --easy-table-body-row-height: 50px;
  --easy-table-body-row-font-size: 14px;

  --easy-table-body-row-hover-font-color: #2d3a4f;
  --easy-table-body-row-hover-background-color: #eee;

  --easy-table-body-item-padding: 10px 15px;

  --easy-table-footer-background-color: #2d3a4f;
  --easy-table-footer-font-color: #c0c7d2;
  --easy-table-footer-font-size: 14px;
  --easy-table-footer-padding: 0px 10px;
  --easy-table-footer-height: 50px;

  --easy-table-rows-per-page-selector-width: 70px;
  --easy-table-rows-per-page-selector-option-padding: 10px;
  --easy-table-rows-per-page-selector-z-index: 1;


  --easy-table-scrollbar-track-color: #2d3a4f;
  --easy-table-scrollbar-color: #2d3a4f;
  --easy-table-scrollbar-thumb-color: #4c5d7a;
  ;
  --easy-table-scrollbar-corner-color: #2d3a4f;

  --easy-table-loading-mask-background-color: #2d3a4f;
}

.customize-table {
  --easy-table-border: 1px solid #242a33;
  --easy-table-row-border: 1px solid #242a33;

  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-font-color: #c1cad4;
  --easy-table-header-background-color: #2a2a2a;

  --easy-table-header-item-padding: 10px 15px;

  --easy-table-body-even-row-font-color: #fff;
  --easy-table-body-even-row-background-color: #303030;

  --easy-table-body-row-font-color: #c0c7d2;
  --easy-table-body-row-background-color: #242424;
  --easy-table-body-row-height: 50px;
  --easy-table-body-row-font-size: 14px;

  --easy-table-body-row-hover-font-color: #242424;
  --easy-table-body-row-hover-background-color: #eee;

  --easy-table-body-item-padding: 10px 15px;

  --easy-table-footer-background-color: #242424;
  --easy-table-footer-font-color: #c0c7d2;
  --easy-table-footer-font-size: 14px;
  --easy-table-footer-padding: 0px 10px;
  --easy-table-footer-height: 50px;

  --easy-table-rows-per-page-selector-width: 70px;
  --easy-table-rows-per-page-selector-option-padding: 10px;
  --easy-table-rows-per-page-selector-z-index: 1;


  --easy-table-scrollbar-track-color: #242424;
  --easy-table-scrollbar-color: #242424;
  --easy-table-scrollbar-thumb-color: #303030;
  ;
  --easy-table-scrollbar-corner-color: #242424;

  --easy-table-loading-mask-background-color: #242424;
}
</style>