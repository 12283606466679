<template>
  <div class="changelog">
    <h1>About Alphorn</h1>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  methods: {

  }
};
</script>
